import React, { ReactNode, useRef } from "react";
import {
	VisualComponent,
	FocusableComponent,
	DataComponent,
	DataObserveKey,
	Button,
	ButtonSize,
	ButtonStyle,
	Icon,
	IconClose,
	cn,
} from "@siteimprove/fancylib";
import { FormControl } from "../form/form";
import { useDesignToken } from "../../context/theme/theme";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./file-input.scss";

type FileInputProps = FormControl<FileList | null> & {
	/** Text and icons to be displayed inside the button. */
	children: ReactNode;
	/** Should selected files be listed on screen */
	listFiles?: boolean;
	/** Should the button be hidden */
	hideButton?: boolean;
	/** Allow multiple files to be selected */
	multiple?: boolean;
	/** Specify accepted file extensions */
	accept?: string;
	/** Can the file input button be clicked */
	disabled?: boolean;
	/** Controls the size of the button and listed files - defaults to medium */
	size?: ButtonSize;
	/** Should the file input button fill full width? */
	fullWidth?: boolean;
	/** How should the file input button look */
	variant?: ButtonStyle;
	/** Should the file input button show a loading indicator */
	loading?: boolean;
	/** Focus the file input immediately when rendering the first time */
	autoFocus?: boolean;
	/** Indicates whether the element is exposed to an accessibility API. */
	ariaHidden?: boolean;
	/** Describe what happens if the file input is clicked */
	ariaLabel?: string;
	/** ID of an element that describes what happens if the file input is clicked */
	ariaLabelledBy?: string;
	/** IDs of the elements that describe the file input's function */
	ariaDescribedBy?: string;
} & VisualComponent &
	FocusableComponent &
	DataComponent &
	DataObserveKey;

export function FileInput(props: FileInputProps): JSX.Element {
	const {
		children,
		value,
		listFiles = true,
		hideButton = false,
		onChange,
		onBlur,
		name,
		id,
		multiple = false,
		accept,
		disabled,
		size = "medium",
		fullWidth,
		variant,
		loading,
		autoFocus,
		tabIndex,
		className,
		style,
		ariaLabel,
		ariaLabelledBy,
		ariaDescribedBy,
		ariaHidden,
		onMouseEnter,
		onMouseLeave,
	} = props;

	const i18n = useLabTranslations();
	const inputRef = useRef<HTMLInputElement>(null);
	const { ColorRed } = useDesignToken();

	return (
		<>
			<input
				data-component={props["data-component"] || "input-field"}
				data-observe-key={props["data-observe-key"]}
				type="file"
				multiple={multiple}
				ref={inputRef}
				onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
					const element = e.target as HTMLInputElement;
					element.value = "";
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange(e.target.files)}
				name={name}
				id={id}
				tabIndex={-1}
				aria-hidden="true"
				accept={accept}
				autoFocus={autoFocus}
				className={cn(scss.input)}
			/>

			{!hideButton && (
				<Button
					onClick={() => {
						inputRef.current?.click();
					}}
					onBlur={onBlur}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					size={size}
					disabled={disabled}
					variant={variant}
					loading={loading}
					tabIndex={tabIndex}
					className={cn(scss.btn, fullWidth && scss.fullWidth, className)}
					style={style}
					aria-label={ariaLabel}
					aria-labelledby={ariaLabelledBy}
					aria-describedby={ariaDescribedBy}
					aria-hidden={ariaHidden}
					aria-haspopup="dialog"
				>
					{children}
				</Button>
			)}

			{listFiles &&
				value &&
				[...value].map((file) => {
					return (
						<div
							key={`${file.name}_${file.lastModified}`}
							className={cn(scss.fileItem, size === "small" && scss.small)}
						>
							{file.name}
							<Button
								size={size}
								type="reset"
								variant="borderless"
								onClick={() => {
									const filteredFiles = [...value].filter(
										(e) => e.name !== file.name && e.lastModified !== file.lastModified
									);
									onChange(filteredFiles as unknown as FileList);
								}}
								aria-label={`${i18n.remove} ${file.name}`}
							>
								<Icon fill={ColorRed}>
									<IconClose />
								</Icon>
							</Button>
						</div>
					);
				})}
		</>
	);
}
