import React, { useState, useEffect } from "react";
import { Link as GatsbyLink } from "gatsby";
import { TextContainer, Ul, Icon, IconAdd, Paragraph } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { FormElementWrapper } from "../form-element-wrapper/form-element-wrapper";
import { FileInput } from "./file-input";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "File Input",
	notepad: "https://hackmd.io/THroRPCZRuSfJ0f9Gi0aYQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="File Input"
			subTitle="Allows users to select and upload files from their devices. Used in forms, applications, or processes where user-provided files are
					necessary."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={FileInput} />
				<Header.H2>Examples</Header.H2>

				<Header.H3>Default</Header.H3>
				<Paragraph>
					The standard way to let users upload a single file. It shows a button that triggers the
					device's file selection dialog. After selection, displays the chosen file name.
				</Paragraph>
				<Example fn={DefaultExample} />
				<Header.H3>Don't List Files</Header.H3>
				<Paragraph>Ideal when file names are irrelevant to the user's task.</Paragraph>
				<Example fn={DontListExample} />
				<Header.H3>Hide Button</Header.H3>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul
					items={[
						"Embed the input within a custom element.",
						"Visually indicate the upload zone with clear instructions and cues.",
					]}
				/>
				<Example fn={HideButtonExample} />
				<Header.H3>Multiple Files</Header.H3>
				<Paragraph>When users need to upload multiple files at once.</Paragraph>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul
					items={[
						"Allow users to select several files simultaneously.",
						"Display the number of selected files and their names in a legible list.",
						"Clearly state the maximum number of permitted files.",
					]}
				/>
				<Example fn={MultipleExample} />
				<Header.H3>File Type</Header.H3>
				<Paragraph>
					When you want to restrict the types of files users can upload (e.g., images only).
				</Paragraph>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul items={["Specify acceptable file types (e.g., png, jpg)"]} />
				<Example fn={FileTypeExample} />
				<Header.H3>Form Element Wrapper</Header.H3>
				<Paragraph>
					Integrate the file input seamlessly into forms, aligning with other form elements.
				</Paragraph>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul
					items={[
						"Apply consistent styling with other form controls (input fields, buttons, etc.).",
						"Ensure proper spacing and alignment within the form.",
						"Provide informative error messages if uploads fail or file types are invalid.",
					]}
				/>
				<Example fn={FormElementWrapperExample} />
				<Header.H3>Variant</Header.H3>
				<Paragraph>Choose the appearance of the file input button to match your design.</Paragraph>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul
					items={[
						<>Prioritize accessibility and maintain clear "button" affordance.</>,
						<>
							See{" "}
							<a href="https://fancy.siteimprove.com/lib/components/Actions%20and%20controls/Button#variants">
								Button variant
							</a>{" "}
						</>,
					]}
				/>
				<Example fn={VariantExample} />
				<Header.H3>Sizes</Header.H3>
				<Paragraph>
					Adjust the size of the file input to fit different contexts and layouts.
				</Paragraph>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul
					items={[
						"Adjust the file input's size for different layouts and screen sizes.",
						"Maintain visual consistency with other elements.",
						"Ensure the input is easily clickable on all devices.",
					]}
				/>
				<Example fn={SizeExample} />
				<Header.H3>Full Width</Header.H3>
				<Paragraph>
					Maximize the width of the file input, especially in wide content areas.
				</Paragraph>
				<Paragraph>
					<b>Best Practice:</b>
				</Paragraph>
				<Ul
					items={[
						"Expand the input to the full width of its container.",
						"Use cautiously, especially on smaller screens.",
					]}
				/>
				<Example fn={FullWidthExample} />
				<Header.H3>Loading</Header.H3>
				<Paragraph>
					Provide visual feedback while the file is being uploaded. Use{" "}
					<GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> to clearly
					communicate the upload status to the user.
				</Paragraph>
				<Example fn={LoadingExample} />
				<Header.H3>Disabled</Header.H3>
				<Paragraph>Prevent user interaction when the file input is not available.</Paragraph>
				<Example fn={DisabledExample} />

				<Header.H2>Properties</Header.H2>
				<Knobs
					component={FileInput}
					initialProps={({ setState }) => ({
						children: "Choose file",
						value: null,
						onChange: (v) => setState({ value: v }),
					})}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>FileInput</Code> when users need to upload files from their device as part of
						a form, application, or process.
					</Paragraph>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>FileInput</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Forms and input/Form">Form</GatsbyLink>: When file
								uploads are part of a structured data entry process. Example can be found in
								Uploading a logo while creating a new email template in a dashboard. (e.g{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FDashboard%2F5765905975%2F1352515706%2FCustom%2FReports%2FNewEmailTemplate%3Flang%3Den-US">
									New email template
								</a>
								).
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>/Dialogs: To
								streamline the upload experience without navigating away from the current page.
							</>,
							<>
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>: To provide a
								focused area for file interaction in dedicated upload sections.
								<Ul
									items={[
										<>
											Example: A "Bulk keyword import" section in SEO settings where users can
											upload files.
										</>,
										<>
											Example: Uploading a site list within a card in Setting {">"}{" "}
											<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSettings%2FSites%2Fv2%2Fadd%3Flang%3Den-US">
												Add sites
											</a>
										</>,
									]}
								/>
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>FileInput</Code> to existing components for visual
								consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						Avoid using for large file uploads. Instead, consider these alternatives for a better
						user experience:
					</Paragraph>
					<Ul
						items={[
							<>
								Break large files into smaller pieces for more reliable and manageable uploads. This
								also enables better error handling and recovery.
							</>,
							<>
								Provide clear visual feedback during uploads, including a progress bar, estimated
								time remaining, and the option to cancel the upload if needed.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between text and background.",
							"Provide clear instructions and error messages.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>Use clear, concise labels (e.g., "Upload File").</>,
							"Avoid technical jargon.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	return (
		<FileInput value={files} onChange={(x: FileList | null) => setFiles(x)}>
			Choose file
		</FileInput>
	);
};

const DontListExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	useEffect(() => {
		console.log(files);
	}, [files]);

	return (
		<FileInput listFiles={false} value={files} onChange={(x: FileList | null) => setFiles(x)}>
			Choose file
		</FileInput>
	);
};

const HideButtonExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);
	const hasFiles = files != undefined && files != null && files.length > 0;

	return (
		<FileInput hideButton={hasFiles} value={files} onChange={(x: FileList | null) => setFiles(x)}>
			Choose file
		</FileInput>
	);
};

const MultipleExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	return (
		<FileInput multiple value={files} onChange={(x: FileList | null) => setFiles(x)}>
			Choose files
		</FileInput>
	);
};

const FileTypeExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	return (
		<FileInput
			accept=".png, .jpg, .jpeg"
			value={files}
			onChange={(x: FileList | null) => setFiles(x)}
		>
			Choose file
		</FileInput>
	);
};

const FormElementWrapperExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);
	const hasFiles = files != undefined && files != null && files.length > 0;

	return (
		<FormElementWrapper
			name="file"
			label="Choose File"
			error={"Selected file invalid"}
			invalid={hasFiles && files[0].size > 1000}
		>
			<FileInput value={files} onChange={(x: FileList | null) => setFiles(x)}>
				<Icon>
					<IconAdd />
				</Icon>
				Add
			</FileInput>
		</FormElementWrapper>
	);
};

const VariantExample = () => {
	const [filesPrimary, setFilesPrimary] = useState<FileList | null>(null);
	const [filesSecondary, setFilesSecondary] = useState<FileList | null>(null);
	const [filesDefault, setFilesDefault] = useState<FileList | null>(null);
	const [filesBorderless, setFilesBorderless] = useState<FileList | null>(null);
	const [filesCtaPrimary, setFilesCtaPrimary] = useState<FileList | null>(null);
	const [filesCtaSecondary, setFilesCtaSecondary] = useState<FileList | null>(null);
	const [filesCtaDefault, setFilesCtaDefault] = useState<FileList | null>(null);

	return (
		<>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					variant="primary"
					value={filesPrimary}
					onChange={(x: FileList | null) => setFilesPrimary(x)}
				>
					Primary
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					variant="secondary"
					value={filesSecondary}
					onChange={(x: FileList | null) => setFilesSecondary(x)}
				>
					Secondary
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput value={filesDefault} onChange={(x: FileList | null) => setFilesDefault(x)}>
					Default
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					variant="borderless"
					value={filesBorderless}
					onChange={(x: FileList | null) => setFilesBorderless(x)}
				>
					Borderless
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					variant="ctaPrimary"
					value={filesCtaPrimary}
					onChange={(x: FileList | null) => setFilesCtaPrimary(x)}
				>
					Primary
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					variant="ctaSecondary"
					value={filesCtaSecondary}
					onChange={(x: FileList | null) => setFilesCtaSecondary(x)}
				>
					Secondary
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					variant="ctaDefault"
					value={filesCtaDefault}
					onChange={(x: FileList | null) => setFilesCtaDefault(x)}
				>
					Default
				</FileInput>
			</div>
		</>
	);
};

const SizeExample = () => {
	const [filesSmall, setFilesSmall] = useState<FileList | null>(null);
	const [filesMedium, setFilesMedium] = useState<FileList | null>(null);
	const [filesLarge, setFilesLarge] = useState<FileList | null>(null);

	return (
		<>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					size="small"
					value={filesSmall}
					onChange={(x: FileList | null) => setFilesSmall(x)}
				>
					Small
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					size="medium"
					value={filesMedium}
					onChange={(x: FileList | null) => setFilesMedium(x)}
				>
					Medium
				</FileInput>
			</div>
			<div style={{ display: "block", marginBottom: "1rem" }}>
				<FileInput
					size="large"
					value={filesLarge}
					onChange={(x: FileList | null) => setFilesLarge(x)}
				>
					Large
				</FileInput>
			</div>
		</>
	);
};

const FullWidthExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	return (
		<FileInput fullWidth value={files} onChange={(x: FileList | null) => setFiles(x)}>
			Choose file
		</FileInput>
	);
};

const LoadingExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	return (
		<>
			<div style={{ display: "inline-block", marginRight: "0.5rem" }}>
				<FileInput loading value={files} onChange={(x: FileList | null) => setFiles(x)}>
					Choose file
				</FileInput>
			</div>
			<div style={{ display: "inline-block", marginRight: "0.5rem" }}>
				<FileInput
					loading
					variant="ctaDefault"
					value={files}
					onChange={(x: FileList | null) => setFiles(x)}
				>
					Choose file
				</FileInput>
			</div>
			<div style={{ display: "inline-block", marginRight: "0.5rem" }}>
				<FileInput loading value={files} onChange={(x: FileList | null) => setFiles(x)}>
					<Icon>
						<IconAdd />
					</Icon>
				</FileInput>
			</div>
		</>
	);
};

const DisabledExample = () => {
	const [files, setFiles] = useState<FileList | null>(null);

	return (
		<>
			<div style={{ display: "inline-block", marginRight: "0.5rem" }}>
				<FileInput disabled value={files} onChange={(x: FileList | null) => setFiles(x)}>
					Disabled
				</FileInput>
			</div>
			<div style={{ display: "inline-block", marginRight: "0.5rem" }}>
				<FileInput
					disabled
					variant="ctaDefault"
					value={files}
					onChange={(x: FileList | null) => setFiles(x)}
				>
					Disabled
				</FileInput>
			</div>
			<div style={{ display: "inline-block", marginRight: "0.5rem" }}>
				<FileInput
					disabled
					ariaLabel="Add file"
					value={files}
					onChange={(x: FileList | null) => setFiles(x)}
				>
					<Icon>
						<IconAdd />
					</Icon>
				</FileInput>
			</div>
		</>
	);
};
